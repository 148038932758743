/* Assuming you're using a CSS file */
.custom-carousel .carousel .control-arrow {
  opacity: 1; /* Make the arrows always visible */
  visibility: visible; /* Make the arrows always visible */
  background-color: rgba(0, 0, 0, 0.419);
  

}

/* Assuming you're using a CSS file */
/* Custom styles for the left and right arrows */
.custom-carousel .carousel .control-arrow.control-prev,
.custom-carousel .carousel .control-arrow.control-next {
  opacity: 1; /* Make the arrows always visible */
  visibility: visible; /* Make the arrows always visible */
  width: 50px; /* Set the width of the arrows */
  height: 50px; /* Set the height of the arrows */
  border-radius: 14px;
  top: calc(50% - 25px);
}

/* Custom styles for the arrow icons (optional) */
.custom-carousel .carousel .control-arrow:before {
  font-size: 24px; /* Adjust the arrow icon size */
}

